import React from "react"

import { Layout } from "../components/layouts/Layout"
import { RenderScript, Section } from "../components/elements"

const LoanApplicationPage = () => (
  <Layout title="Loan Applications">
    <Section>
      <RenderScript url="https://form.jotform.com/jsform/201895338379065" />
    </Section>
  </Layout>
)

export default LoanApplicationPage
